.slot-container {
  border-left: var(--one) solid black;
  border-top: var(--one) solid black;
  margin-top: calc(-1 * var(--one));
  margin-left: calc(-1 * var(--one));
  width: fit-content;
  height: fit-content;
  .slot {
    // Use this for debugging off-by-1 pixel garbage
    //background-color: red;
    border-right: var(--one) solid black;
    border-bottom: var(--one) solid black;
    &.grid {
      position: relative;
      width: calc(64px - var(--one));
      height: calc(64px - var(--one));
      .item {
        width: calc(64px - var(--one));
        height: calc(64px - var(--one));
        &.wide {
          position: absolute;
          z-index: 9999;
        }
      }
    }
    .item {
      background-color: rgb(173, 208, 255);
      &.empty {
        background-color: #2A323D;
      }
      overflow: hidden;
      width: calc(128px - var(--one));
      height: calc(128px - var(--one));
      .quantity {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}
