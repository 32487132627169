.dropdown {
  display: inline-block;
}

label {
  &.danger {
    color: var(--bs-danger);
  }
  &.warning {
    color: var(--bs-warning);
  }
}
