.job-container {
  transition: background-color 0.35s ease;
  display: flex;
  flex-direction: row;
  flex: auto;
  border-radius: 5px;
  background-color: var(--secondary-color);
  min-height: 84px;
  min-width: 300px;

  &.highlight {
    background-color: var(--secondary-color-highlight);
  }
  h4 {
    padding-left: 5px;
    font-size: medium;
    position: absolute;
    text-shadow: 0px 0px 6px black;
    z-index: 1;
    pointer-events: none;
  }
}
input[type="range"] {
  accent-color: green;
}
label {
  padding-right: 10px;
}
.jobs {
  .job-outer {
    margin-bottom: 20px;
  }
  user-select: none;
}
.storage {
  input {
    width: 30px;
  }
}
.survivor {
  transition: background-color 0.35s ease;
  text-align: center;
  width: 64px;
  height: 64px;
  margin: 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  position: relative;

  &:hover {
    background-color: var(--primary-color-highlight);
  }
  div {
    margin: auto;
  }
  .counter {
    position: absolute;
    right: 5px;
    font-size:12px;
  }
  .progressbar {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
  }
}
