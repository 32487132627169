@import "bootstrap/dist/css/bootstrap.min.css";

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roc Grotesk";
  src: url("./assets/fonts/Roc Grotesk - Compressed ExtraBold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  --one: 1px;
  @media (-webkit-min-device-pixel-ratio: 1.5) {
    --one: calc(1px / 1.5);
  }
  @media (-webkit-min-device-pixel-ratio: 2) {
    --one: 1px;
  }
  --bs-body-font-family: "Lato";
  --bs-body-bg: rgb(16, 16, 19);
  --bs-body-color: #fff;
  --primary-color: #444;
  --primary-color-highlight: #555;
  --secondary-color: #222;
  --secondary-color-highlight: #333;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;

  a:link {
    color: #bbb;
    width: fit-content;
  }
  a:visited {
    color: #888;
  }
  a:hover,
  a:active {
    color: #fff;
  }
}

.btn {
  color: #fff;
  &:hover {
  color: #fff;
  }
  &.stealth {
    padding: 0;
    background-color: inherit;
    border: none;
    text-decoration: underline;
    vertical-align: inherit;
  }
}

.lr-pad {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

input {
  &.stealth {
    padding: 0;
    border: none;
    text-decoration: underline;
    vertical-align: inherit;
  }
}

input[type="checkbox"] {
  width: 30px;
  height: 30px;
}

.row {
  display: flex;
  flex-direction: row;
}
.col {
  display: flex;
  flex-direction: column;
}
.wrap {
  flex-wrap: wrap;
}

.margin-border {
  margin-top: 10px;
  margin-bottom: 10px;
  &:not(.container) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.showParent {
  .showChild {
    display: none;
  }
  &:hover .showChild {
    display: block;
  }
}

.pull-bottom {
  margin-top: auto !important;
}

.pull-top {
  margin-bottom: auto !important;
}

.pull-right {
  margin-left: auto !important;
  justify-content: flex-end;
  text-align: end;
}

.pull-left {
  margin-right: auto !important;
  justify-content: flex-start;
  text-align: start;
}

.hide {
  display: none;
}

nav {
  width: 100%;
  .navbar-collapse {
    &.show,
    &.collapsing {
      display: flex;
    }
    justify-content: right;
  }
  .nav-item {
    height: 24px;
  }
}

.logo-header {
  background: linear-gradient(90deg, rgb(61 70 77 / 60%), rgb(32 37 41 / 60%));
  padding: 0.75rem 0;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left;
  display: flex;
  .xurb {
    font-family: "Roc Grotesk";
    font-size: 80px;
    margin-top: -20px;
    margin-bottom: -30px;
  }
  a:link {
    margin: 10px;
    color: #bbb;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
